const sub = [
  {
    threshold: 1,
    end: 50,
    content: 'one'
  },
  {
    threshold: 51,
    end: 101,
    content: 'two'
  },
  {
    threshold: 102,
    end: 142,
    content: 'three'
  },
  {
    threshold: 143,
    end: 190,
    content: 'four'
  },
  {
    threshold: 191,
    end: 255,
    content: 'five'
  },
  {
    threshold: 256,
    end: 309,
    content: 'six'
  },
  {
    threshold: 310,
    end: 355,
    content: 'seven'
  },
  {
    threshold: 356,
    end: 390,
    content: 'eight'
  },
  {
    threshold: 391,
    end: 435,
    content: 'nine'
  },
  {
    threshold: 436,
    end: 449,
    content: 'ten'
  },
  // {
  //   threshold: 440,
  //   end: 452,
  //   content: 'eleven'
  // }
]

export default sub