import "../styles/index.scss";
import CanvasScrollClip from "./scroll";
import config from "./subtitles.config";
import ButtonCtrl from './ButtonCtrl'

let loadCount = 0;
const frameNumber = 449;
const loader = document.querySelector(".loader");
const introContainer = document.querySelector(".intro .visual-wrapper");
const percents = document.querySelector(".intro .percents");
const intro = document.querySelector(".intro");
const splash = document.querySelector(".splash");

let introPassed = false;

new CanvasScrollClip(
  document.querySelector(".main-container"),
  {
    framePath: "/public/images/sequence/000.png",
    frameCount: frameNumber,
    scrollArea: 10000,
    imageLoaded: () => {
      loadCount++;
      const percent = (loadCount / frameNumber) * 100;
      percents.innerHTML = Math.round(percent) + "%";
      loader.style.transform = "translateX(" + percent + "%)";
    },
    onProgress: (index) => {
      if (index > 0) {
        splash.classList.remove("show");
        // intro.classList.add('hidden')
        document.querySelector(".main-container").classList.remove("hidden");
        document.querySelector('.button').classList.add('active')
      } else {
        if (introPassed) {
          splash.classList.add("show");
        }
        intro.classList.remove("hidden");
        document.querySelector(".main-container").classList.add("hidden");
      }
      for (let conf of config) {
        if (index >= conf.threshold && index <= conf.end) {
          document.querySelector("." + conf.content).classList.add("show");
        } else {
          document.querySelector("." + conf.content).classList.remove("show");
        }
      }
    },
  },
  () => {
    loader.remove();
    document.body.classList.add('free')
    introContainer.classList.add("loaded");
    setTimeout(() => {
      introContainer.classList.add("full");

      setTimeout(() => {
        splash.classList.add("show");
        introPassed = true;
      }, 1200);
    }, 500);
  }
);

// document.querySelector(".eleven a").addEventListener("click", (e) => {
//   e.preventDefault();
//   window.scrollTo({
//     top: 10000,
//     behavior: 'smooth'
//   })
// });

var form = document.getElementById("contact-form");

async function handleSubmit(event) {
  event.preventDefault();
  fetch(event.target.action, {
    method: form.method,
    body: JSON.stringify({
      firstname: document.querySelector('#firstname').value,
      lastname: document.querySelector('#lastname').value,
      email: document.querySelector('#email').value,
      message: document.querySelector('#message').value
    }),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
    .then((response) => {
      if (response.ok) {
        form.reset();
        document.querySelector('.success').classList.add('active')
      } else {
        response.json().then((data) => {
          if (Object.hasOwn(data, "errors")) {
            console.log(data.errors);
          } else {
            console.log(data)
          }
        });
      }
    })
    .catch((error) => {
      console.log(error);
    });
}
form.addEventListener("submit", handleSubmit);

const button = new ButtonCtrl(document.querySelector('.button'));
button.DOM.el.addEventListener('click', () => {
  if (window.scrollY < 9000) {
    window.scrollTo({
      top: 10000,
      behavior: 'smooth'
    })
  } else {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }
})
